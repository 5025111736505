<template>
  <div id="app">
    <div class="Header">
      <div class="Navigation-bar">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                 background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
          <!-- Logo作为菜单项 -->
          <el-menu-item class="logo-item">
            <img src="@/image/XiangYunInfo/img/logo-text.png" alt="Logo" class="logo-image"/>
          </el-menu-item>
          <el-menu-item index="1" class="left">
            <router-link to="/">关于</router-link>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">飞行区</template>
            <el-menu-item index="2-1">
              <router-link to="FlyOnline">联飞（飞行中心）</router-link>
            </el-menu-item>
            <el-menu-item index="2-2">VA系统（虚拟航司）</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">空管区</template>
            <el-menu-item index="3-1"><router-link to="/ATCLogin">管制</router-link></el-menu-item>
            <el-menu-item index="3-2"><router-link to="/ATC/recruit">招募通知</router-link></el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">热点</template>
            <el-menu-item index="6-1">更新通知</el-menu-item>
            <el-menu-item index="6-2">航空新闻</el-menu-item>
            <el-menu-item index="6-2">中国民用航空局</el-menu-item>
          </el-submenu>
          <el-menu-item index="4"><a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=ZhZ0T8uV24uO2rH6rnzTqdtU54pst_7D&authKey=%2FFOfzLHJeRFD1dlObeIgNcUAD7oeC7LNpbYpO287eFqloNoibDU23UUIm%2FAhN2N4&noverify=0&group_code=870163594">加入群聊</a></el-menu-item>
          <el-menu-item index="5" disabled>商城</el-menu-item>
          <div class="container-utc">
            <p class="UTCtime">UTC Now: {{ formattedUtcTime }}</p>
          </div>
        </el-menu>
      </div>
    </div>
    <div class="Content">
      <div class="content-left">
        <el-row class="tac">
          <el-col :span="12">
            <el-menu :default-active="activeIndex2" class="el-menu-vertical-demo" @open="handleOpen"
                     @close="handleClose" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
              <router-link to="/FlyOnline">
                <el-menu-item index="A" class="atta1"><span slot="title" class="ata2">数据中心</span></el-menu-item>
              </router-link>
              <a href="#" @click.prevent="">
                <el-menu-item index="B" class="atta1"><span slot="title" class="ata2">个人主页</span></el-menu-item>
              </a>
              <a href="#"  @click.prevent="goToMyLicence">
              <el-menu-item index="F" class="atta1"><span slot="title" class="ata2">我的执照</span></el-menu-item>
              </a>
              <a href="http://map.xiangyunfligh.cn"><el-menu-item index="C" class="atta1"><span slot="title" class="ata2">在线地图</span></el-menu-item></a>
              <el-menu-item index="D" class="atta1"><span slot="title" class="ata2">航图查询</span></el-menu-item>
              <el-menu-item index="E" class="atta1"><span slot="title" class="ata2">航路查询</span></el-menu-item>
              <el-menu-item index="G" class="atta1"><span slot="title" class="ata2">气象查询</span></el-menu-item>
              <el-button @click="qingchu">退出登录</el-button>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="content-right2">
        <div class="head">
          <img :src="UserInfo.headImg">
        </div>
        <div class="contentA">
          <img class="X" v-if="violations === 1" src="@/image/Personal/Violations/Violations1.png" alt="Exception Image 1">
          <img class="X" v-if="violations === 2" src="@/image/Personal/Violations/Violations2.png" alt="Exception Image 2">
          <img class="X" v-if="violations === 3" src="@/image/Personal/Violations/Violations3.png" alt="Exception Image 3">
          <img class="X" v-if="violations === 3" src="@/image/Personal/Violations/Violations4.png" alt="Exception Image 4">
          <div class="head2">
            <div class="info-foundation">
              <img :src="UserInfo.avatarURL">
              <div class="head2-right">
                <span id="name">{{ UserInfo.name }}</span><br/>
                <span id="numbering" :style="{ color: getColorb(UserInfo.numbering) }">{{ UserInfo.numbering }}</span>
                <div  v-if="userEdit">
                <el-button type="text" @click="dialogFormVisible = true">编辑个人资料</el-button>

                <el-dialog title="个人资料编辑" :visible.sync="dialogFormVisible">
                  <el-form :model="edit">
                    <div class="editAvatar">
                      <span id="editAvatar">上传头像：</span><br/>
                    <input type="file" ref="fileInput" @change="previewImage"/>
                    <img v-if="previewImageUrl" :src="previewImageUrl" alt="Preview Image"/>
                    <button @click="uploadAvatar">保存并上传头像</button>
                    </div>
                      <div class="editPassword">
                        <span id="editPassword">更改密码：</span><br/>
                        <el-input class="inputpassword1" v-model="edit.Oldpassword" placeholder="请输入旧密码"></el-input>
                        <p id="editerror" v-if="edit.error.Oldpassword">{{edit.error.Oldpassword}}</p>
                        <el-input class="inputpassword1" v-model="edit.password" placeholder="请输入新密码"></el-input>
                        <p id="editerror"  v-if="edit.error.password">{{edit.error.password}}</p>
                        <el-button class="yespassword" @click="editPassword">确认修改</el-button>
                      </div>
                    <div class="inAirline">
                      <span>所属航司：</span><br/>
                      <el-input class="inAirlineinput" v-model="edit.airline" placeholder="请输入三字代码"></el-input>
                      <p id="editairline"  v-if="edit.error.airline">{{edit.error.airline}}</p>
                      <el-button class="yesairline" @click="editAirline">提交 </el-button>
                    </div>

                    <div class="XYVIP">
                      <span>翔云大会员VIP  PLUS：</span><br/>
                      <el-button type="warning" @click="XyVip">前往开通</el-button>
                    </div>
                  </el-form>

                </el-dialog>
              </div>
                <div class="credit">
                  <span :style="{ color: getColor(UserInfo.credit) }">信用分：<span>{{ UserInfo.credit }}</span></span>
                </div>
              </div>
            </div>
            <div class="wenzi">
              <div class="University">
              <div class="N" v-if="UserInfo.University===null"><span :style="{ color: GradeColor }">高校认证</span></div>
                <div class="YUniversity" v-else><img src="@/image/Personal/gaoxiao.png"/><span id="Universityzi">{{UserInfo.University }}</span></div>
            </div>
              <div class="dj">
              <div class="N" v-if="UserInfo.dj===null"><span>主播认证</span></div>
                <div class="Ydj" v-if="isBiliBili"><img :src="biliBiliIconSrc"/><span>{{UserInfo.dj.substring(3,99) }}</span></div>
                <div class="Ydj" v-if="isDouyin"><img :src="douyinIconSrc"/><span>{{UserInfo.dj.substring(3,99) }}</span></div>
              </div>
                <div class="official">
              <div class="N" v-if="UserInfo.official===null">官方认证</div>
              <div class="Yofficial" v-else><img src="@/image/Personal/v.png"/><span>{{UserInfo.official }}</span></div>
                </div>
              <div class="creditFlay">
                <div class="N" v-if="UserInfo.creditFlay===null" ><span >授信飞行员</span></div>
                <div class="NcreditFlay" v-else><img src="@/image/Personal/shouxin.png"/>{{UserInfo.creditFlay}}</div>
              </div>
              <div class="NA">
                <img v-if="UserInfo.grade === 1" src="@/image/Personal/feixingyuan.png"/>
                <img v-else src="@/image/Personal/guanzhi.png"/>
                <span v-if="UserInfo.identity" :style="{ color: GradeColor }">{{UserInfo.identity}}</span>
                <span v-if="UserInfo.grade === 1" :style="{ color: GradeColor }">飞行员</span>
              </div>
              <div class="NA"><span v-if="UserInfo.airline" style="color: #98ff00;font-size: 20px;padding-left: 13px">{{UserInfo.airline }}</span><span v-else>航空公司</span></div>
            </div>
            <span v-if="userEdit" style="color: #8F98A0">邮箱：{{UserInfo.email}}</span>
          </div>
        </div>
        <div class="contentB">
          <hr id="hr">
          <div class="Data">
            <span id="tips-01-top">当前生涯数据</span>
            <div class="Data-Sheet">
              <div class="Data-top"><span>VA虚航生涯数据</span></div>
              <div class="grade">
                <img src="@/image/Personal/1.jpg">
                <span style="color: red">》》》》暂未开通！敬请期待~</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/XiangYunInfo/Header.css'
import '@/css/ell.css'
import '@/css/Bottom.css'
import '@/css/Personal/Content.css'
import axios from '@/axios-instance'; // 确保路径正确
import MyLicenceView from "@/views/New/MyLicenceView.vue";



export default {
  data() {
    return {
      violations:'',
      userEdit:false,
      previewImageUrl: '', // 用于存储预览的图片URL
      dialogFormVisible: false,
      edit: {
        error:{
          Oldpassword:'',
          password:'',
          airline:''
        },
        email: '',
        Oldpassword:'',
        password:'',
        airline:'',
      },
      formLabelWidth: '100px',
      currentUserID: null,
      userData: {},
      isOwnProfile: false,
      UserInfo: {
        identity:'',
        official: '',
        dj: '',
        University: '',
        creditFlay: '',
        credit: '',
        headImg: '',
        avatarURL: '',
        userId:'',
        id: '',
        date: '',
        name: '',
        numbering: '',
        grade: 1,
        airline: '',
        address: '',
        email: '',
      },
      biliBiliIconSrc: require('@/image/Personal/bli.png'),
      douyinIconSrc: require('@/image/Personal/dou.png'),
      activeIndex: '2-1',
      activeIndex2: 'B',
      formattedUtcTime: null,
    }
  },
  mounted() {
    this.userinfo();
    this.updateFormattedUtcTime(); // 初始化时立即更新一次
    setInterval(this.updateFormattedUtcTime, 1000); // 每秒更新日期部分
  },
  created() {
    this.Whoisuser(); // 在组件创建时调用此方法
  },
  computed: {
    isBiliBili() {
      // 检查 UserInfo.dj 是否不为 null 并且第一个字符为 'B'
      return this.UserInfo.dj !== null && this.UserInfo.dj.startsWith('B');
    },
    isDouyin() {
      // 检查 UserInfo.dj 是否不为 null 并且第一个字符为 '抖'
      return this.UserInfo.dj !== null && this.UserInfo.dj.startsWith('抖');
    },
    MyLicenceView() {
      return MyLicenceView
    },
    GradeColor() {
      switch (this.UserInfo.grade) {
        case 2:
        case 3:
        case 4:
          return '#985a00';
        case 5:
        case 6:
        case 7:
          return '#00d9ff';
        case 8:
        case 9:
        case 10:
          return '#800008';
        case 11:
        case 12:
          return '#c700ff';
        default:
          return '#a5a5a5'; // 默认颜色
      }
    },
  },
  methods: {
    goToMap(){
      this.$router.push({path: `/Map`});
    },
    qingchu() {
      localStorage.removeItem('user-token');
      // 可以选择重定向到登录页面或其他操作
      this.$router.push('/login');
    },
    goToMyLicence() {
      const token = localStorage.getItem('user-token');

      if (token) {
        const userId = localStorage.getItem('user-id');
        this.$router.push({path: `/MyLicence/user/${userId}`});
      } else {
        this.$router.push('/login');
      }
    },
    XyVip(){
      const userId = localStorage.getItem('user-id');
      this.$router.push({path: `/vip/${userId}`});
    },
    async editPassword() {
      if (!this.edit.Oldpassword.trim()) {
        this.edit.error.Oldpassword = '密码不能为空!';
        return;
      }

      try {
        const response = await axios.post("/edit/query/password", {
          password: this.edit.Oldpassword,
          userId: this.UserInfo.userId
        });

        if (response.data.code === 1) {
          // 密码验证成功
          this.edit.error.Oldpassword
          const updateResponse = await axios.post("/edit/password", {
            password: this.edit.password, // 注意这里应该是新密码
            userId: this.UserInfo.userId
          });

          if (updateResponse.data.code === 1) {
            this.edit.error.Oldpassword
            this.$message.success("密码修改成功");
          } else {
            this.$message.error("密码修改失败");
          }
        } else {
          this.edit.error.Oldpassword = '密码错误';
        }
      } catch (error) {
        this.edit.error.Oldpassword = '请求错误';
        console.error('请求错误', error);
      }
    },
    async editAirline() {
      if (!this.edit.airline.trim()) {
        this.edit.error.airline = '航司代码不能为空!';
        return;
      } else {
        const response = await axios.post("/edit/airline", {
          userId: this.UserInfo.userId,
          airline: this.edit.airline
        })
        if (response.data.code === 1) {
          this.$message.success("航司修改成功")
        } else {
          this.$message.error("航司修改失败")
        }
      }
    },
    Whoisuser(){
      const HeUserId = this.$route.params.userId;
      const MyUserId = localStorage.getItem('user-id');
      if (MyUserId === HeUserId) {
        this.userEdit = true;
      } else {
        this.userEdit = false;
      }
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        // 使用FileReader API预览图片
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageUrl = e.target.result; // 将预览图片的URL赋值给previewImageUrl
        };
        reader.readAsDataURL(file);
        this.selectedFile = file; // 保存选择的文件以备上传
      } else {
        this.$message.error('没有选择图片');
      }
    },
    async uploadAvatar()
    {
      if (!this.selectedFile) {
        this.$message.error('没有图片需要上传');
        return;
      }
      try {
        const formData = new FormData();
        formData.append('image', this.selectedFile);
        const userId = localStorage.getItem('user-id');
        formData.append('userId', userId); // 使用从localStorage获取的userId值
        await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$message.success("上传成功，请刷新")
      } catch (error) {
        console.error('图片上传失败:', error);
        this.$message.error('图片上传失败，请稍后再试！');
      }
    }
,
    getColor(credit) {
      if (credit < 70) {
        return 'red';
      } else if (credit < 80) {
        return 'orange';
      } else if (credit >= 90) {
        return 'green';
      }
      // Default color if none of the conditions are met
      return 'black';
    },
    getColorb(numbering){
      if (numbering === '未获得连线资格') {
        return 'red';
      } else  {
        return '#6e6e6e'
      }
    },
    async userinfo() {
      try {
        const userId = this.$route.params.userId;
        const response = await axios.post(`/user/${userId}`);
        const data = response.data;
        this.currentUserID = data.userId
        this.UserInfo = data; // 假设this.username是Vue组件中的一个数据属性
        this.UserInfo.University=data.university;
        this.violations=data.violations
      } catch (error) {
        console.error('Failed to fetch user information', error);
      }
    },
    updateFormattedUtcTime() {
      const now = new Date();
      // 获取年月日部分，拼接时分秒
      this.formattedUtcTime = `${now.getUTCFullYear()}-${('0' + (now.getUTCMonth() + 1)).slice(-2)}-${('0' + now.getUTCDate()).slice(-2)}--${('0' + now.getUTCHours()).slice(-2)}:${('0' + now.getUTCMinutes()).slice(-2)}:${('0' + now.getUTCSeconds()).slice(-2)}`;
    }
  }
}
</script>
<style>
.editAvatar{
  margin-left: 10px;
  width: 100%;
}
#editAvatar{
  color: #171D25;
  font-size: 15px;
}
.editAvatar input{
  width: 50%;
  height: 30px;
  margin-top: 5px;
}
.editPassword{
  margin-top: 20px;
  margin-left: 10px;
  width: 40%;
}
#editPassword{
  color: #171D25;
  font-size: 15px;
}
.editPassword input{
  margin-top: 5px;
  margin-bottom: 10px;
  width: 70%;
  height: 35px;
}
.yespassword{
  width: 40%;
}
#editerror{
  color: red;
  font-size: 13px;
}
.inAirline{
  margin-left: 10px;
  margin-top: 20px;
  width: 20%;
  height: 30%;
}
.inAirline span{
  font-size: 15px;
  color: #171D25;
}
.inAirlineinput{
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.yesairline{
  width: 50%;
}
.XYVIP{
  margin-left: 10px;
  margin-top: 20px;
}
</style>