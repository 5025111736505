<template>
  <div id="app">
    <div class="Header">
      <div class="Navigation-bar">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
          <!-- Logo作为菜单项 -->
          <el-menu-item class="logo-item">
            <img src="@/image/XiangYunInfo/img/logo-text.png" alt="Logo" class="logo-image" />
          </el-menu-item>
          <el-menu-item index="1" class="left"><router-link to="/">关于</router-link></el-menu-item>
          <el-submenu index="2">
            <template slot="title">飞行区</template>
            <el-menu-item index="2-1"><router-link to="FlyOnline">联飞（飞行中心）</router-link></el-menu-item>
            <el-menu-item index="2-2">VA系统（虚拟航司）</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">空管区</template>
            <el-menu-item index="3-1"><router-link to="/ATCLogin">管制</router-link></el-menu-item>
            <el-menu-item index="3-2"><router-link to="/ATC/recruit">招募通知</router-link></el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">热点</template>
            <el-menu-item index="6-1">更新通知</el-menu-item>
            <el-menu-item index="6-2">航空新闻</el-menu-item>
            <el-menu-item index="6-2">中国民用航空局</el-menu-item>
          </el-submenu>
          <el-menu-item index="4"><a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=ZhZ0T8uV24uO2rH6rnzTqdtU54pst_7D&authKey=%2FFOfzLHJeRFD1dlObeIgNcUAD7oeC7LNpbYpO287eFqloNoibDU23UUIm%2FAhN2N4&noverify=0&group_code=870163594">加入群聊</a></el-menu-item>
          <el-menu-item index="5" disabled>商城</el-menu-item>
          <div class="container-utc">
            <p class="UTCtime">UTC Now: {{ formattedUtcTime }}</p>
          </div>
        </el-menu>
      </div>
    </div>
    <div class="Content">
      <div class="content-left">
        <el-row class="tac" >
          <el-col :span="12">
            <el-menu :default-active="activeIndex2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
              <router-link to="/FlyOnline"><el-menu-item index="A" class="atta1"><span slot="title" class="ata2">数据中心</span></el-menu-item></router-link>
              <a href="#" @click.prevent="goToPersonal"><el-menu-item index="B" class="atta1"><span slot="title" class="ata2">个人主页</span></el-menu-item></a>
              <a href="#" @click.prevent=""><el-menu-item index="F" class="atta1"><span slot="title" class="ata2">我的执照</span></el-menu-item></a>
              <a href="http://map.xiangyunfligh.cn"><el-menu-item index="C" class="atta1"><span slot="title" class="ata2">在线地图</span></el-menu-item></a>
              <el-menu-item index="D" class="atta1"><span slot="title" class="ata2">航图查询</span></el-menu-item>
              <el-menu-item index="E" class="atta1"><span slot="title" class="ata2">航路查询</span></el-menu-item>
              <el-menu-item index="G" class="atta1"><span slot="title" class="ata2">气象查询</span></el-menu-item>
              <el-button @click="qingchu">退出登录</el-button>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="content-r-a">
        <!--普通执照 联飞-->
        <div class="Normal">
          <span id="Normal-tips">普通执照</span><hr>
          <div class="licence">
          <div class="General-Aviation">
            <div class="abc-img"><img src="@/image/MyLicence/tonghang.svg"></div>
            <div class="abc-span">
              <span id="type-A">A级通航执照</span><br/>
              <span class="have-name">持有人：{{UserInfo.name}}</span><br/>
              <span class="day">有效期：{{UserInfo.createTime}}至2099年12月31日</span><br/>
              <span class="type-A-tips">说明：本照注册即或得，仅本平台有效</span>
            </div>
          </div>

          <div class="Connection">
            <div class="abc-img"><img src="@/image/MyLicence/lianxian.svg"></div>
            <div class="abc-span">
              <span id="type-B">B级连线执照</span><br/>
              <div>
              <span class="have-name-B" v-if="UserInfo.numbering !=='未获得连线资格'">持有人：{{UserInfo.name}}</span><span id="have-name" v-if="UserInfo.numbering ==='未获得连线资格'" style="color: red">未持有</span><br/>
              <span class="have-name-C" v-if="UserInfo.numbering !=='未获得连线资格'">编号：{{UserInfo.numbering}}</span><a href="#" @click.prevent="goToConnection"><span id="have-name" v-if="UserInfo.numbering ==='未获得连线资格'" style="color: red">去考试获取>>></span></a><br/>
              <span class="day" v-if="UserInfo.numbering !=='未获得连线资格'">有效期：{{UserInfo.REcreateTime}}至2099年12月31日</span><br/>
              </div>
              <span class="type-B-tips">说明：本照需要通过资格验证连线考试，仅代表本平台有效</span>
            </div>
          </div>

          </div>
      </div>
        <!--中级执照 航司 ATC-->
        <div class="Normal-1" >
          <span id="Normal-tips">中等执照</span><hr>
          <div class="licence">
            <div class="General-Aviation">
              <div class="abc-img"><img src="@/image/MyLicence/hangsi.svg"></div>
              <div class="abc-span">
                <span id="type-A">C级航司执照</span><br/>
                <span id="have-name" v-if="UserInfo.VA ===null">姓名：{{UserInfo.name}}</span><span id="have-name" v-if="UserInfo.VA !==null"   style="color: red">未持有</span><br/>
                <span class="have-name-C" v-if="UserInfo.VA ===null">编号：{{UserInfo.numbering}}</span><br/>
                <span class="day" v-if="UserInfo.VA ===null">有效期：{{UserInfo.VAcreateTime}}至2099年12月31日</span><br/>
              </div>
            </div>

            <div class="ATCee" >
              <div id="abc-img"><img src="@/image/MyLicence/tatai.svg"></div>
              <div id="abc-span">
                <span id="type-A" >ATC执照</span><br/>
                <div>
                <span id="have-name-B"  v-if="UserInfo.grade > 1">持有人：{{UserInfo.name}}</span><span id="have-name" v-if="UserInfo.grade ===1" style="color: red">未持有</span><br/><span id="have-name-B"  v-if="UserInfo.grade === 1 " style="color: red">未持有，欢迎您加入空管</span><a href="#" @click.prevent="goToATCee"><span id="have-name" v-if="UserInfo.grade ===1" style="color: red">去申请>>></span></a>
                <span class="have-name-C" v-if="UserInfo.grade > 1">编号：{{UserInfo.numbering}}</span><br/>
                <span class="have-name-C" v-if="UserInfo.grade > 1">等级：{{ATCgrade}}</span><br/>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <h1>{{UserInfo.userId}}</h1>
  </div>
</template>

<script>
import axios from '@/axios-instance'; // 确保路径正确

export default {
  data(){
    return{
      formattedUtcTime: null,
      activeIndex: '2-1',
      activeIndex2: 'F',
      UserInfo: {
        VA:'',
        official: '',
        dj: '',
        University: '',
        creditFlay: '',
        credit: '',
        headImg: '',
        avatarURL: '',
        userId:this.$route.params.userId,
        id: '',
        date: '',
        name: '',
        numbering: '',
        grade: '',
        airline: '',
        address: '',
        email: '',
      },
    }

  },
  mounted() {
    this.userinfo()
    this.updateFormattedUtcTime(); // 初始化时立即更新一次
    setInterval(this.updateFormattedUtcTime, 1000); // 每秒更新日期部分
  },
  computed: {
    ATCgrade() {
      switch (this.UserInfo.grade) {
        case "2":
          return 'T1塔台管制员';
        case "3":
          return 'T2塔台管制员';
        case "4":
          return 'T3塔台管制员';
        case "5":
          return '见习雷达管制员';
        case "6":
          return '放单雷达管制员';
        case "7":
          return '正式雷达管制员';
        case "8":
          return '实习管制教员';
        case "9":
          return '正式管制教员';
        case "10":
          return '全席管制主任';
        case "11":
          return '监管';
        case "12":
          return '平台管理员';
        default:
          return '飞行员';
      }
    },
  },
  methods:{
    goToMap(){
      this.$router.push({path: `/Map`});
    },
    goToATCee(){
      const token = localStorage.getItem('user-token');

      if (token) {
        const userId = localStorage.getItem('user-id');
        this.$router.push({path: `/ATC/application/${userId}`});
      } else {
        this.$router.push('/ATC/application');
      }
    },
    goToConnection(){
      const token = localStorage.getItem('user-token');

      if (token) {
        const userId = localStorage.getItem('user-id');
        this.$router.push({path: `/Connection/exam/${userId}`});
      } else {
        this.$router.push('/Connection/exam');
      }
    },
    async userinfo() {
      try {
        const userId = this.$route.params.userId;
        const response = await axios.post(`/user/${userId}`);
        const data = response.data;
        this.currentUserID = data.userId
        this.UserInfo = data; // 假设this.username是Vue组件中的一个数据属性
        this.violations = data.violations
      } catch (error) {
        console.error('Failed to fetch user information', error);
      }
    },
    qingchu() {
      localStorage.removeItem('user-token');
      // 可以选择重定向到登录页面或其他操作
      this.$router.push('/login');
    },
    goToPersonal() {
      const token = localStorage.getItem('user-token');

      if (token) {
        const userId = localStorage.getItem('user-id');
        this.$router.push({path: `/Personal/user/${userId}`});
      } else {
        this.$router.push('/login');
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    updateFormattedUtcTime() {
      const now = new Date();
      // 获取年月日部分，拼接时分秒
      this.formattedUtcTime = `${now.getUTCFullYear()}-${('0' + (now.getUTCMonth() + 1)).slice(-2)}-${('0' + now.getUTCDate()).slice(-2)}--${('0' + now.getUTCHours()).slice(-2)}:${('0' + now.getUTCMinutes()).slice(-2)}:${('0' + now.getUTCSeconds()).slice(-2)}`;
    }
  }
}
</script>

<style>
.Normal{
  width: 85%;
  height: 0px;
  margin-top: 6%;
}
.Normal  hr{
  width: 120%;
  color: #171D25;
  margin-left: 20%;
  margin-top: 5%;
}
#Normal-tips{
  font-size: 20px;
  color: #000000;
  margin-left: 20%;
}
.licence{
  width: 150%;
  display: flex;
}
.General-Aviation{
  margin-top: 5%;
  margin-left: 10%;
  width: 70%;
  height: 200px;
  background-color: #42b983;
  display: flex;
}
.abc-span{
  flex: 0.5;
}

.abc-span{
  margin-top: 5px;
  margin-left: 10px;
   flex: 1.5;
 }
#type-A{
  font-size: 20px;
  color: #171D25;
  margin-left:50px;
}

.type-A-tips{
  margin-left: -7%;
  display: grid;
  align-content: end; /* 将内容对齐到底部 */
  min-height: 13vh;
}
.type-B-tips{
  margin-left: -7%;
  display: grid;
  align-content: end; /* 将内容对齐到底部 */
  min-height: 11vh;
}

#have-name{
  color: black;
  font-size: 15px;
  margin-left: 1px;
}
.Connection{
  margin-top: 5%;
  margin-left: 10%;
  width: 70%;
  height: 200px;
  background-color: #ffb774;
  display: flex;
}

.Normal-1{
  margin-top: 35%;
  width: 85%;
  height: 400px;
}
.Normal-1  hr{
  width: 120%;
  color: #171D25;
  margin-left: 20%;
  margin-top: 5%;
}
#type-B{
  font-size: 20px;
  margin-left: 40px;
}
.ATCee{
  margin-top: 5%;
  margin-left: 10%;
  width: 70%;
  height: 200px;
  background-color: #ffb774;
  display: flex;
}
</style>