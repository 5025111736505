import Vue from 'vue'
import VueRouter from 'vue-router'
import Personal from '@/views/New/PersonalView.vue';


Vue.use(VueRouter)

const routes = [
  {
    mode: 'hash',
    path: '/',
    name: 'home',
    component: () => import('../views/New/XiangYunInfoView.vue'),
    meta: { title: 'CXY|翔云模拟飞行平台官网-首页' }
  },
  {
    mode: 'hash',
    path: '/Register',
    name: '注册',
    component: () => import('../views/New/RegisterView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-呼号注册' ,
      description:'翔云模拟飞行平台呼号注册，联飞资格注册'}
  },
  {
    mode: 'hash',
    path: '/Admin/Index/:userId',
    name: '管理员面板',
    component: () => import('../views/New/Admin/AdminIndex.vue'),
    meta: { title: '管理员面板' }
  },
  {
    mode: 'hash',
    path: '/Admin/Login',
    name: '管理员登录',
    component: () => import('../views/New/Admin/LoginAdmin.vue'),
    meta: { title: '管理员登录' }
  },
  {
    mode: 'hash',
    path: '/FlyOnline',
    name: '联飞中心',
    component: () => import('../views/New/FlyOnlineView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-联飞中心' }
  },
  {
    mode: 'hash',
    path: '/Login',
    name: '登录',
    component: () => import('../views/New/LoginView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-登录' }
  },
  {
    mode: 'hash',
    path: '/Vip/:userId',
    name: 'Vip开通',
    component: () => import('../views/New/VipView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-VIP开通' }
  },
  {
    mode: 'hash',
    path: '/Connection/exam/:userId',
    name: '连线考试',
    component: () => import('../views/New/ConnectionExamView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-考试系统' }
  },
  {
    mode: 'hash',
    path: '/404',
    name: '404',
    component: () => import('../views/New/404View.vue'),
    meta: { title: '404' }
  },
  {
    mode: 'hash',
    path: '/MyLicence/user/:userId',
    name: '我的执照',
    component: () => import('../views/New/MyLicenceView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-飞行执照' }
  },
  {
    mode: 'hash',
    path: '/ATC/recruit',
    name: 'ATC招募',
    component: () => import('../views/New/ATC/recruitView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-ATC招募通知' ,
      description:'翔云模拟飞行平台管制员招募通知，通知文件'}
  },
  {
    mode: 'hash',
    path: '/Personal/user/:userId',
    name: '个人主页',
    component: Personal ,
    meta: { requiresAuth: true ,title: 'CXY|翔云模拟飞行-飞行员主页',
      description:'翔云模拟飞行平台飞行员主页，飞行员个人资料编辑，查看个人资料'}
  },
  {
    mode: 'hash',
    path: '/ATCLogin',
    name: '管制员登录',
    component: () => import('../views/New/ATC/ATCLoginView.vue'),
    meta: { title: 'CXY|翔云模拟飞行-ATC管制员面板-登录',
      description:'登录搜寻更多有趣的事，联飞，注册账户，成为ATC' }
  },
  {
    mode: 'hash',
    path: '/ConsoleATC/:userId',
    name: '管制员控制台',
    component: () => import('../views/New/ATC/ConsoleATCView.vue'),
  meta: { title: 'CXY|翔云模拟飞行-ATC管制员面板-控制台' ,
    description:'翔云模拟飞行平台ATC面板，ATC专用面板，ATC管制员面板'}
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  // 只有当目标路由是 /personal 时才进行 token 检查
  if (to.path === '/personal' && to.path==='/MyLicenceView.vue') {
    const token = localStorage.getItem('user-token');

    if (!token) {
      // 如果没有 token，则重定向到登录页面
      next('/login');
    } else {
      // 如果有 token，则允许访问 personal 页面
      next();
    }
  } else {
    // 如果目标不是 /personal，则直接放行
    next();
  }
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '翔云模拟飞行平台';
  next();
});
export default router
